import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;

`

export const TabButtonsContainer = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  position: relative;
  border-bottom: 1px solid var(--grey26);

  & li {
    flex: 1;
  }
`

export const TabButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 1rem;
  color: var(--grey10);

  & span:first-child {
    font-size: 24px;
  }

  & span:last-child {
    font-size: 16px;
  }

  &.active {
    color: var(--blue);
  }
`

export const ActiveTabIndicator = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--blue);
  transition-duration: 0.3s;
  transition-property: transform;
`