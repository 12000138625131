import React from 'react'
import * as S from './styles.js'

const SmallLoading = ({ className }) => {
  return (
    <S.SmallLoadingWrapper className={className}>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </S.SmallLoadingWrapper>
  )
}

export default SmallLoading
