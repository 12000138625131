import { useEffect, useMemo, useRef, useState } from 'react'

import * as S from './styles'
import Tab from './Tab'

export default function Tabs(props) {
  const { children } = props

  const tabComponents = children.filter(child => child.type === Tab)

  const tabComponentsAmount = tabComponents.length

  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [activeTabIndicatorStyles, setActiveTabIndicatorStyles] = useState({
    transform: 'translateX(0)',
    width: 0
  })

  const buttonsContainerRef = useRef()

  const activeTabComponent = useMemo(
    () => tabComponents[activeTabIndex],
    [tabComponents]
  )

  useEffect(() => {
    function updateActiveTabIndicatorStyles() {
      const containerWidth = buttonsContainerRef.current.clientWidth

      const indicatorWidth = containerWidth / tabComponentsAmount
      const indicatorTranslation = indicatorWidth * activeTabIndex

      setActiveTabIndicatorStyles({
        transform: `translateX(${indicatorTranslation}px)`,
        width: indicatorWidth
      })
    }

    updateActiveTabIndicatorStyles()

    const controller = new AbortController()

    window.addEventListener(
      'resize',
      () => {
        updateActiveTabIndicatorStyles()
      },
      { signal: controller.signal }
    )

    return () => {
      controller.abort()
    }
  }, [activeTabIndex, tabComponentsAmount])

  return (
    <S.Container>
      <S.TabButtonsContainer ref={buttonsContainerRef}>
        {tabComponents.map(({ props: tabProps, key }, index) => (
          <li key={key}>
            <S.TabButton
              className={index === activeTabIndex ? 'active' : ''}
              onClick={setActiveTabIndex.bind(null, index)}
            >
              <span className={tabProps.iconClass}>{tabProps.icon}</span>
              <span>{tabProps.title}</span>
            </S.TabButton>
          </li>
        ))}
        <S.ActiveTabIndicator style={activeTabIndicatorStyles} />
      </S.TabButtonsContainer>
      {activeTabComponent}
    </S.Container>
  )
}

Tabs.Tab = Tab
