import Image from 'next/image'

import * as S from './styles'

import { ASSET_PATHS } from '@/services/paths'
import { Fragment } from 'react'

export default function EmailLists({
  lists,
  onRemove,
  removeButtonId,
  withBorder,
  disabled 
}) {
  return (
    <>
      <S.SharedListEmails withBorder={withBorder}>
        {lists.map(({ key, title, users, type }) => (
          <Fragment key={key}>
            {!!title && (
              <li>
                <span className="subtitle">{title}</span>
              </li>
            )}
            {users.map(item => (
              <S.SharedListEmailItem
                key={item.id_v1}
                success={type === 'success'}
                errors={type === 'errors'}
              >
                <span>{item.email}</span>
                {!!onRemove && (
                  <button onClick={() => onRemove(item)} id={removeButtonId} disabled={disabled}>
                    <Image
                      src={ASSET_PATHS.icon_close}
                      width={16}
                      height={16}
                      alt={'Close'}
                    />
                  </button>
                )}
              </S.SharedListEmailItem>
            ))}
          </Fragment>
        ))}
      </S.SharedListEmails>
    </>
  )
}
