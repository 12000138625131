import { useMemo, useState, useEffect } from 'react'

import * as S from './styles'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import { i18n } from '@/translate/i18n'
import AccessManagement from './AccessManagement'

export default function LinkForm({
  config,
  isLoading,
  onShowManagementView,
  accessList
}) {
  const [labels, setLabels] = useState({})
  const [linkWasCopied, setLinkWasCopied] = useState(false)

  const { t } = useTranslation()

  const collectionId = config?.collectionId
  const encryptedExpirationTimestamp = config?.encryptedExpirationTimestamp

  useEffect(() => {
    setLabels({
      directLinkLabel: t('labels.directLink'),
      copyLinkButton: t('buttons.copyLink'),
      linkCopiedButton: t('buttons.linkCopied')
    })
  }, [t])

  const link = useMemo(() => {
    const result = new URL(`${process.env.DOMAIN_URL}/SharedCollection`)
    result.searchParams.append('id', collectionId)
    result.searchParams.append('expires', encryptedExpirationTimestamp)

    return result.toString()
  }, [collectionId, encryptedExpirationTimestamp])

  const copyLinkToClipboard = async () => {
    try {
      await window.navigator.clipboard.writeText(link)

      setLinkWasCopied(true)
    } catch {
      Swal.fire({
        icon: 'error',
        title: i18n.t('messages.clipboardPermissionsErrorTitle'),
        text: i18n.t('clipboardPermissionsErrorText')
      })
    }
  }

  if (isLoading) {
    return null
  }

  return (
    <S.LinkFormContainer>
      <S.LinkFormFieldContainer>
        <span>{labels.directLinkLabel}</span>
        <input value={link} disabled />
      </S.LinkFormFieldContainer>

      <S.LinkFormButton
        onClick={copyLinkToClipboard}
        className={linkWasCopied ? 'active' : ''}
      >
        {linkWasCopied ? labels.linkCopiedButton : labels.copyLinkButton}
      </S.LinkFormButton>

      {!!accessList.length && (
        <AccessManagement
          usersList={accessList}
          onShowManagementView={onShowManagementView}
        />
      )}
    </S.LinkFormContainer>
  )
}
