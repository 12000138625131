import Tabs from '@/components/Tabs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EmailForm from './EmailForm'
import LinkForm from './LinkForm'

export default function TabsView({
  onShare,
  allUsers,
  config,
  accessList,
  onShowManagementView,
  onCancel
}) {
  const { t } = useTranslation()

  const [labels, setLabels] = useState({
    linkTabButton: '',
    emailTabButton: ''
  })

  useEffect(() => {
    setLabels({
      linkTabButton: t('buttons.linkTab'),
      emailTabButton: t('buttons.emailTab')
    })
  }, [t])

  return (
    <>
      <Tabs>
        <Tabs.Tab
          key="email"
          icon="mail"
          iconClass="material-icons-outlined"
          title={labels.emailTabButton}
        >
          <EmailForm
            onShare={onShare}
            allUsers={allUsers}
            config={config}
            accessList={accessList}
            onShowManagementView={onShowManagementView}
            onCancel={onCancel}
          />
        </Tabs.Tab>
        <Tabs.Tab
          key="link"
          icon="link"
          iconClass="material-icons"
          title={labels.linkTabButton}
        >
          <LinkForm
            config={config}
            accessList={accessList}
            onShowManagementView={onShowManagementView}
          />
        </Tabs.Tab>
      </Tabs>
    </>
  )
}
