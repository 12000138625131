import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import EmailLists from './EmailLists'
import * as S from './styles'

export default function ShareResultView({ result, onClose, onAddNewEmails }) {
  const [labels, setLabels] = useState({
    successTitle: '',
    errorTitle: '',
    closeButton: '',
    addNewEmailsButton: ''
  })

  const { t } = useTranslation()

  useEffect(() => {
    setLabels({
      successTitle: t('subtitles.successShareCollection'),
      errorTitle: t('subtitles.errorShareCollection'),
      closeButton: t('buttons.close'),
      addNewEmailsButton: t('buttons.addNewEmails')
    })
  }, [t])

  const emailLists = useMemo(() => {
    const lists = []

    if (result.success?.length) {
      lists.push({
        key: 'success',
        users: result.success,
        type: 'success',
        title: labels.successTitle
      })
    }
    if (result.error?.length) {
      lists.push({
        key: 'error',
        users: result.error,
        type: 'error',
        title: labels.errorTitle
      })
    }

    return lists
  }, [result, labels])

  return (
    <S.ShareResultViewContainer>
      <EmailLists lists={emailLists} />

      <S.SharedListButtons>
        <button type="button" onClick={onClose} id={'btn_close_results'}>
          {labels.closeButton}
        </button>
        <button onClick={onAddNewEmails} type="submit" id="btn_add_new_emails">
          {labels.addNewEmailsButton}
        </button>
      </S.SharedListButtons>
    </S.ShareResultViewContainer>
  )
}
