import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'

import * as S from './styles'
import AccessManagement from './AccessManagement'
import EmailLists from './EmailLists'
import SmallLoading from '@/components/SmallLoading'

export default function EmailForm(props) {
  const { onShare, allUsers, onCancel, accessList, onShowManagementView } =
    props

  const { t } = useTranslation()

  const [labels, setLabels] = useState({
    shareText: '',
    labelText: '',
    placeholderText: '',
    cancelText: '',
    successSubtitleText: '',
    errorSubtitleText: '',
    errorInputText: '',
    loadingAlt: ''
  })
  const [currentInputValue, setCurrentInputValue] = useState('')
  const [errorInput, setErrorInput] = useState(false)
  const [suggestionsList, setSuggestionsList] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])
  const [listedUsers, setListedUsers] = useState(
    allUsers?.filter(user => {
      return accessList.every(item => item.id_v1 !== user.id_v1)
    }) || []
  )
  const [isLoading, setIsLoading] = useState(false)

  const handleInputChange = async e => {
    const insertedValue = e.target.value
    setCurrentInputValue(insertedValue)

    if (
      e.nativeEvent.inputType === 'deleteContentBackward' &&
      insertedValue === ''
    ) {
      setErrorInput(false)
      return
    }

    if (insertedValue.length < 3) {
      setSuggestionsList([])
      return
    }

    if (
      insertedValue.length < 3 &&
      e.nativeEvent.inputType === 'deleteContentBackward'
    ) {
      setSuggestionsList([])
      return
    }

    if (insertedValue) {
      const newSuggestionList = listedUsers.filter(item => {
        const lowerCaseInsertedValue = insertedValue.toLowerCase()
        return item.email.includes(lowerCaseInsertedValue)
      })

      setSuggestionsList(newSuggestionList)
    }
  }

  const handleShare = async event => {
    setIsLoading(true)

    event.preventDefault()

    const result = await onShare(selectedUsers)

    if (result) {
      setSelectedUsers([])
    }

    setIsLoading(false)
  }

  const handleSuggestionClick = user => {
    setSelectedUsers(state => state.concat(user))
    setListedUsers(state => state.filter(item => item.id_v1 !== user.id_v1))

    setSuggestionsList([])
    setCurrentInputValue('')
  }

  const handleRemoveSelectedUser = removedUser => {
    setSelectedUsers(state =>
      state.filter(user => user.id_v1 !== removedUser.id_v1)
    )
    setListedUsers(state => state.concat(removedUser))
  }

  useEffect(() => {
    setLabels({
      shareText: t('buttons.share'),
      labelText: t('labels.shareCollection'),
      placeholderText: t('placeholders.shareCollection'),
      cancelText: t('buttons.cancel'),
      successSubtitleText: t('subtitles.subtitleSuccessShareCollection'),
      errorSubtitleText: t('subtitles.subtitleErrorShareCollection'),
      errorInputText: t('messages.errorInputShared'),
      loadingAlt: t('alts.load')
    })
  }, [t])

  useEffect(() => {
    if (
      currentInputValue.length > 6 &&
      currentInputValue.includes('@') &&
      !listedUsers.length
    ) {
      setErrorInput(true)
    } else {
      setErrorInput(false)
    }
  }, [currentInputValue, listedUsers])

  useEffect(() => {
    if (!allUsers) {
      return
    }

    setListedUsers(
      allUsers.filter(user =>
        accessList.every(item => item.id_v1 !== user.id_v1)
      )
    )
  }, [allUsers, accessList])

  return (
    <form onSubmit={handleShare}>
      <label htmlFor={labels.shareText}>{labels.labelText}</label>
      <input
        type="text"
        placeholder={labels.placeholderText}
        name={labels.shareText}
        onChange={handleInputChange}
        autoComplete="off"
        style={{
          border: `${errorInput ? '1px solid #F03D3E' : '1px solid #e1e1e1'}`
        }}
        value={currentInputValue}
        id={'inp_share'}
        disabled={isLoading}
      />
      {errorInput && <p className="inputError">{labels.errorInputText}</p>}

      {!!suggestionsList.length && (
        <S.SharedListSuggestions absolute={!!selectedUsers.length}>
          {suggestionsList.map(item => (
            <li
              key={item.id}
              onClick={handleSuggestionClick.bind(null, item)}
              id={`add_email_list`}
            >
              {item.email}
            </li>
          ))}
        </S.SharedListSuggestions>
      )}

      {!!selectedUsers.length && (
        <EmailLists
          lists={[{ key: 'selected-users', users: selectedUsers }]}
          onRemove={handleRemoveSelectedUser}
          disabled={isLoading}
        />
      )}

      {!!accessList.length && (
        <AccessManagement
          usersList={accessList}
          onShowManagementView={onShowManagementView}
        />
      )}

      <S.SharedListButtons>
        <button
          type="button"
          onClick={onCancel}
          id={'btn_cancel_share'}
          disabled={isLoading}
        >
          {labels.cancelText}
        </button>
        <button
          type="submit"
          disabled={!selectedUsers.length || errorInput || isLoading}
          id="btn_share"
          className={isLoading ? 'loading' : ''}
        >
          {labels.shareText}

          <SmallLoading className="loading-indicator" />
        </button>
      </S.SharedListButtons>
    </form>
  )
}
