import React, { createContext, useContext, useState } from 'react'

const initialState = {
  showModal: false,
  settings: {
    type: null,
    classes: null,
    config: {}
  },
  MODAL_TYPES: null
}

export const ModalContext = createContext(initialState)

export function ModalProvider({ children }) {
  const [showModal, setShowModal] = useState(initialState.showModal)
  const [settings, setSettings] = useState(initialState.settings)

  const callModal = settings => {
    setSettings(settings)
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const MODAL_TYPES = {
    alert: 'alert',
    content_details: 'content_details',
    edit_avatar: 'edit_avatar',
    edit_language: 'edit_language',
    edit_password: 'edit_password',
    edit_data: 'edit_data',
    edit_guardian_data: 'edit_guardian_data',
    delete_account: 'delete_account',
    viewer_help: 'viewer_help',
    banner_video: 'banner_video',
    rename_collection: 'rename_collection',
    delete_collection: 'delete_collection',
    move_collection: 'move_collection',
    create_collection_folder: 'create_collection_folder',
    share_collection: 'share_collection',
    new_content: 'new_content',
    move_collection_drop: 'move_collection_drop'
  }

  return (
    <ModalContext.Provider
      value={{ showModal, settings, MODAL_TYPES, callModal, closeModal }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export function useModal() {
  return useContext(ModalContext)
}
