import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as S from './styles'
import EmailLists from './EmailLists'

export default function ManagementView({ onClose, onUnshare, accessList }) {
  const [labels, setLabels] = useState({
    returnButton: '',
    addNewEmailsButton: '',
    emailsWithAccessSubtitle: ''
  })

  const { t } = useTranslation()

  useEffect(() => {
    setLabels({
      returnButton: t('buttons.return'),
      addNewEmailsButton: t('buttons.addNewEmails'),
      emailsWithAccessSubtitle: t('subtitles.emailsWithAccess')
    })
  }, [t])

  useEffect(() => {
    if (!accessList.length) {
      onClose()
    }
  }, [accessList])

  return (
    <S.ManagementViewContainer>
      <EmailLists
        lists={[
          {
            title: labels.emailsWithAccessSubtitle,
            key: 'users-with-access',
            users: accessList
          }
        ]}
        onRemove={onUnshare}
      />

      <S.SharedListButtons>
        <button type="button" onClick={onClose} id={'btn_back_to_share_forms'}>
          {labels.returnButton}
        </button>
        <button type="submit" onClick={onClose} id="btn_add_new_emails">
          {labels.addNewEmailsButton}
        </button>
      </S.SharedListButtons>
    </S.ManagementViewContainer>
  )
}
