import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import { trackSelfDescribingEvent } from '@snowplow/browser-tracker'

import { useModal } from '@/contexts/ModalContext'
import { useAuth } from '@/contexts/AuthContext'

import { ENDPOINTS, sendPostRequest } from '@/services/api'
import { onUncaughtError } from '@/services/userAlerts'
import { SNOWPLOW_SCHEMAS } from '@/services/paths'

import SmallLoading from '@/components/SmallLoading'

import { i18n } from '@/translate/i18n'

import * as S from './styles'
import ShareResultView from './ShareResultView'
import ManagementView from './ManagementView'
import TabsView from './TabsView'

const ShareCollectionModal = ({ config }) => {
  const { closeModal } = useModal()
  const { user } = useAuth()

  const { t } = useTranslation()

  const [labels, setLabels] = useState({
    shareCollectionTitle: ''
  })

  const [showLoading, setShowLoading] = useState(true)
  const [allUsers, setAllUsers] = useState()
  const [getUsers, setGetUsers] = useState()
  const [accessList, setAccessList] = useState(config.sharedList)
  const [shareResult, setShareResult] = useState()
  const [activeView, setActiveView] = useState('tabs')

  useEffect(() => {
    setLabels({
      shareCollectionTitle: t('titles.shareCollection')
    })
  }, [t])

  useEffect(() => {
    async function fetchUsers() {
      setShowLoading(true)
      const body = {
        attributes: ['id', 'email', 'id_v1'],
        collection_id: config.collectionId
      }
      await sendPostRequest(ENDPOINTS.list_related_users, body)
        .then(response => {
          const users = response.data.msg.Items
          setAllUsers(users)
        })
        .catch(error => {
          const msg = error.data || error
          console.log(msg)
          onUncaughtError()
        })
        .finally(() => {
          setShowLoading(false)
        })
    }
    fetchUsers()
  }, [getUsers])

  const shareCollection = async selectedUsers => {
    trackSelfDescribingEvent({
      event: {
        schema: SNOWPLOW_SCHEMAS.event_button_click,
        data: {
          button_name: SNOWPLOW_SCHEMAS.collection_item_menu_share_confirm
        }
      },
      context: [
        {
          schema: SNOWPLOW_SCHEMAS.entity_collection,
          data: {
            id: config.collectionId,
            content_id: config.content_id,
            type: config.type,
            name: config.name,
            origin_user_email: config.origin_user_email,
            origin_id: config.origin_id,
            shares: `${config.shares}`
          }
        },
        {
          schema: SNOWPLOW_SCHEMAS.entity_module,
          data: {
            content_id: `${config.content_id}`,
            module_id: null
          }
        }
      ]
    })

    const body = {
      id: config.collectionId,
      share_with_users_id: selectedUsers.map(item => `${item.id_v1}`),
      origin_user_email: user.email
    }

    let requestWasSuccessful

    try {
      const response = await sendPostRequest(ENDPOINTS.share_collection, body)

      setAccessList(state => state.concat(response.data.success))

      setShareResult({
        success: response.data.success,
        error: response.data.error
      })
      setActiveView('result')

      requestWasSuccessful = true
    } catch (error) {
      const msg = error.data || error
      console.log(msg)
      onUncaughtError()

      requestWasSuccessful = false
    }

    return requestWasSuccessful
  }

  const unshareCollection = async value => {
    setShowLoading(true)

    const body = {
      id: config.collectionId,
      user_id: value.id_v1
    }

    let requestWasSuccessful = true

    try {
      const response = await sendPostRequest(ENDPOINTS.delete_collection, body)

      if (response.status === 200) {
        setGetUsers(!getUsers)

        Swal.fire({
          icon: 'success',
          title: i18n.t('swal.success'),
          html: i18n.t('messages.collectionUnshared')
        })

        setAccessList(state => state.filter(item => item.id_v1 !== value.id_v1))
      }
    } catch (error) {
      const msg = error.data || error
      console.log(msg)
      onUncaughtError()

      requestWasSuccessful = false
    }

    setShowLoading(false)

    return requestWasSuccessful
  }

  const closeModalShare = () => {
    config.setAction(state => !state)
    closeModal()
  }

  if (!config) {
    return null
  }

  return (
    <S.SharedListContainer>
      <S.SharedListTitle>
        <p>
          {labels.shareCollectionTitle} {config.name}
        </p>
        <button
          id="btn_closemodal_shared"
          type="button"
          onClick={closeModalShare}
        >
          <span className="material-icons">close</span>
        </button>
      </S.SharedListTitle>

      <S.SharedListContent>
        {!showLoading && activeView === 'tabs' && (
          <TabsView
            allUsers={allUsers}
            onShare={shareCollection}
            config={config}
            isLoading={showLoading}
            accessList={accessList}
            onShowManagementView={setActiveView.bind(null, 'management')}
            onCancel={closeModalShare}
          />
        )}
        {!showLoading && activeView === 'management' && (
          <ManagementView
            accessList={accessList}
            onClose={setActiveView.bind(null, 'tabs')}
            onUnshare={unshareCollection}
          />
        )}
        {!showLoading && activeView === 'result' && (
          <ShareResultView
            result={shareResult}
            onClose={closeModalShare}
            onAddNewEmails={setActiveView.bind(null, 'tabs')}
          />
        )}
      </S.SharedListContent>

      {showLoading && <SmallLoading />}
    </S.SharedListContainer>
  )
}

export default ShareCollectionModal
