import { useEffect, useMemo, useState } from 'react'

import * as S from './styles'
import ImageWithFallback from '@/components/ImageWithFallback'
import { getUrlForResize } from '@/services/functions'
import { ASSET_PATHS } from '@/services/paths'
import { useTranslation } from 'react-i18next'

export default function AccessManagement({ usersList, onShowManagementView }) {
  const [labels, setLabels] = useState({
    emailsWithAccessSubtitle: '',
    manageAccessButton: ''
  })

  const visibleUsers = useMemo(
    () => (usersList.length > 3 ? usersList.slice(0, 2) : usersList),
    [usersList]
  )

  const { t } = useTranslation()

  useEffect(() => {
    setLabels({
      emailsWithAccessSubtitle: t('subtitles.emailsWithAccess'),
      manageAccessButton: t('buttons.manageAccess')
    })
  }, [t])

  return (
    <S.AccessManagementContainer>
      <strong>{labels.emailsWithAccessSubtitle}</strong>

      <S.AccessManagementContent>
        <ul>
          {visibleUsers.map(user => (
            <li key={user.email}>
              <ImageWithFallback
                fallbackSrc={ASSET_PATHS.default_avatar}
                width={40}
                title={user.email}
                height={40}
                src={getUrlForResize(
                  `users/images/${user.id_v1}`,
                  40,
                  40,
                  'png'
                )}
                alt={user.email}
                autoSize={false}
              />
            </li>
          ))}
          {visibleUsers.length !== usersList.length && (
            <li>
              <div className="long-list-indicator">
                +{usersList.length - visibleUsers.length}
              </div>
            </li>
          )}
        </ul>

        <button onClick={onShowManagementView}>{labels.manageAccessButton}</button>
      </S.AccessManagementContent>
    </S.AccessManagementContainer>
  )
}
