/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components'

const thinScrollbar = css`
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: var(--grey23);
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--grey24);
    border-radius: 50px;
  }
`

export const SharedListContainer = styled.div`
  background: var(--light);
  border-radius: 10px;
  padding: 1.8rem 0 0;
  width: fit-content;
  min-width: 36rem;
  min-width: 36rem;
  width: 47vw;
  max-width: 650px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  button {
    background-color: transparent;
  }

  & form {
    padding: 0 2.25rem;
    position: relative;

    label {
      color: var(--dark);
      font-size: 0.875rem;
      font-weight: 500;
    }

    input {
      border-radius: 10px;
      @media only screen and (max-width: 410px) {
        font-size: 12px;
      }
    }
  }

  .inputError {
    font-size: 12px;
    color: var(--error);
  }

  form {
    @media only screen and (max-width: 550px) {
      padding: 0.75rem 1rem 0 1rem;
    }
  }
`

export const SharedListSuggestions = styled.ul`
  max-height: 14rem;
  overflow-y: scroll;
  border: 1px solid var(--grey4);
  position: ${props => (props.absolute ? 'absolute' : 'static')};
  width: ${props => (props.absolute ? '88%' : '100%')};
  z-index: 3;
  box-shadow: 0px 4px 4px 0px #00000033;

  li {
    color: var(--grey21);
    font-size: 16px;
    padding: 1rem;
    cursor: pointer;
    background-color: var(--light);
    border-bottom: 1px solid var(--grey12);

    &:hover {
      background-color: var(--grey12);
    }
  }
`

export const SharedListEmails = styled.ul`
  list-style: none;
  padding-top: 12px;
  max-height: 15rem;
  overflow-y: auto;
  margin-top: 24px;
  border-top: ${props => props.withBorder && `1px solid var(--grey4)`};

  ${thinScrollbar};

  .subtitle {
    padding-bottom: 16px;
    color: var(--grey2);
    font-size: 14px;
    font-weight: 700;
    display: block;
  }
`

export const SharedListEmailItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props =>
    props.success
      ? 'var(--successLight)'
      : props.errors
        ? 'var(--errorLight)'
        : 'var(--grey5)'};
  padding: 12px 8px;
  border-radius: 5px;
  color: var(--grey21);
  font-size: 16px;
  margin-bottom: 8px;

  button {
    background-color: var(--light);
    padding: 4px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  &:last-of-type {
    margin-bottom: ${props => (props.errors ? '20px' : '0px')};
  }

  @media only screen and (max-width: 550px) {
    font-size: 13px;
  }
`

export const SharedListButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2.5rem;
  gap: 1.25rem;

  button {
    padding: 0.7rem 1.56rem;
    border-radius: 5px;
    font-size: 0.875rem;
    font-weight: 500;
  }

  button[type='submit'] {
    background-color: var(--blue);
    color: var(--light);
  }

  button[type='button'] {
    border: 1px solid var(--grey11);
    color: var(--grey14);
  }

  button:disabled,
  button[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }

  /* button .loading-indicator {
    display: none;
  } */

  button .loading-indicator {
    display: none;
  }

  button.loading {
    position: relative;
    overflow: hidden;

    .loading-indicator {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0;
      background-color: var(--blue);

      .lds-ring {
        transform: scale(0.5);
        background-color: var(--blue);

        div {
          border-color: #fff transparent transparent transparent;
        }
      }
    }
  }
`

export const SharedListTitle = styled.div`
  border-bottom: 1px solid var(--grey4);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.25rem 1.56rem 2.25rem;
  word-wrap: break-word;
  word-break: break-all;

  p {
    color: var(--dark);
    font-size: 1.5rem;
    font-weight: 600;
  }
`

export const SharedListContent = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 1.8rem;

  ${thinScrollbar};
`

export const LinkFormContainer = styled.div`
  padding: 0 2.25rem;
  width: 100%;
`

export const LinkFormFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  color: #4f4f4f;
  font-size: 1rem;
  line-height: 1.125;

  input {
    padding: 1rem 0.75rem;
    border: 1px solid var(--grey26);
    background-color: var(--grey5);
    border-radius: 0.5rem;
  }
`

export const LinkFormButton = styled.button`
  margin-top: 1.5rem;
  background-color: var(--blue2) !important;
  color: #fff;
  padding: 1rem;
  font-size: 1rem;
  line-height: 1.125;
  font-weight: 600;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &:hover,
  &.active {
    background-color: var(--blue) !important;
  }
`

export const AccessManagementContainer = styled.div`
  margin-top: 5rem;

  strong {
    font-size: 14px;
  }
`

export const AccessManagementContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;

    li:nth-child(n + 2) {
      margin-left: -0.75rem;
    }

    img {
      border-radius: 3rem;
      border: 1px solid #fff;
    }

    div.long-list-indicator {
      background-color: var(--grey2);
      border-radius: 3rem;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  button {
    text-decoration: underline;
    color: var(--blue);
    font-weight: 500;
  }
`

export const ManagementViewContainer = styled.div`
  padding: 0 2.25rem;
`

export const ShareResultViewContainer = styled.div`
  padding: 0 2.25rem;
`
